.App {
  text-align: center;
  height: 100vh;
  width: 100vw;
  max-height: 100vh;
  max-width: 100vw;
  margin: auto;
  overflow: hidden;
  /* background-color: #251b30; */
  color: #fff;
}

.fikahjulet {
  background-color: #251b30;
}

.tomtbola {
  background-color: #d9d9d9;
}

.fikahjulet-button {
  background-color: #7438b6;
}

.tomtbola-button {
  background-color: #f27656;
}

.SaveToPng {
  padding: 1em;
  /* background-color: #251b30; */
  max-height: 100vh;
}

.HeaderSvg {
  width: 70vh;
  max-width: 70vw;
  max-height: 10vh;
}

.WheelSvg {
  width: 70vh;
  max-width: 80vw;
  max-height: 50vh;
}

.Button {
  width: auto;
  height: auto;
  white-space: nowrap;
  color: white;
  /* background-color: #7438b6; */
  border-style: none;
  border-radius: 1.5rem;
  justify-content: center;
  align-self: auto;
  align-items: center;
  margin-top: 2rem;
  margin-bottom: 0;
  padding: 1rem 2rem;
  font-family: neue-kabel, sans-serif;
  font-size: 1rem;
  font-weight: 700;
  line-height: 0.8rem;
  display: inline-block;
}

.Button:hover {
  color: #fff;
  /* background-color: #7438b6; */
  background-image: linear-gradient(rgba(0, 0, 0, 0.25), rgba(0, 0, 0, 0.25));
  text-decoration: none;
  cursor: pointer;
}

.Center {
  margin: auto;
}

.RehnFont {
  font-size: 6vw;
  font-family: neue-kabel, sans-serif;
  font-weight: 900;
  color: white;
}

.TextArea {
  border-radius: 1rem;
  font-size: 1rem;
  padding: 1rem;
  min-width: 10rem;
  min-height: 10rem;
  max-height: 50vh;
  max-width: 70vw;
  font-family: "Open Sans", sans-serif;
}

.TextRow {
  border-radius: 0.5rem;
  font-size: 1rem;
  padding: 0.5rem;
  width: auto;
  min-width: 10rem;
  max-width: 70vw;
  height: 1.1rem;
  overflow: hidden;
  resize: horizontal;
  white-space: nowrap;
  font-family: "Open Sans", sans-serif;
  z-index: 1;
}

.Logo {
  margin: auto;
  margin-top: 2rem;
  margin-bottom: 2rem;
}

.floating-image {
  width: auto; /* Adjust the width as needed */
  height: auto; /* Maintain aspect ratio */
  position: absolute;
  top: 40%;
  left: 80%;
  transform: translate(-50%, -50%);
  z-index: 0; /* Ensure the image is on top of the background */
}

.in-front {
  z-index: 1;
}

.rehnis {
  width: auto;
  height: auto;
  position: absolute;
  bottom: 0%;
  left: 5%;
  z-index: 1;
}

.speech-bubble {
  position: relative;
  max-width: 300px;
  margin: 20px;
  padding: 15px;
  background-color: #fff;
  color: #000;
  border-radius: 10px;
  font-size: 1.2em;
  font-weight: bolder;
}

.speech-bubble:after {
  content: "";
  position: absolute;
  top: 100%;
  left: 20px;
  border-width: 20px;
  border-style: solid;
  border-color: #fff transparent transparent transparent;
}

.speech-content {
  list-style-type: decimal; /* Use decimal numbers for the ordered list */
  text-align: left;
}
